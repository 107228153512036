<template>
  <v-flex v-if="user">
    <v-flex v-if="type === 'String'">
      <v-subheader>
        {{ label }}
        <span v-if="isRequired" class="secondary--text">*</span>
      </v-subheader>
      <v-text-field
        v-if="maxlength"
        v-model="f.value"
        rounded
        filled
        :rules="rules"
        :required="isRequired"
        color="red"
        :disabled="isDisplayMode"
        :readonly="!isEditable"
        counter
        :maxlength="maxlength"
        dense
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
      <v-text-field
        v-else
        v-model="f.value"
        rounded
        filled
        :rules="rules"
        :required="isRequired"
        color="red"
        :disabled="isDisplayMode"
        :readonly="!isEditable"
        dense
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
    </v-flex>
    <v-flex v-else-if="type === 'Email'">
      <v-subheader>
        {{ label }}
        <span v-if="isRequired" class="secondary--text">*</span>
      </v-subheader>
      <v-text-field
        v-model="f.value"
        rounded
        filled
        :rules="emailRules"
        :required="isRequired"
        color="red"
        :disabled="isDisplayMode"
        :readonly="!isEditable"
        counter
        :maxlength="maxlength"
        dense
        @input="searchForExistingAccount"
        :error-messages="f.errors"
        validate-on-blur
      >
        <template v-slot:append v-if="hint">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="grey lighten-1">
                mdi-help-circle-outline
              </v-icon>
            </template>
            {{ hint }}
          </v-tooltip>
        </template>
      </v-text-field>
    </v-flex>
    <v-alert
      id="alert-existing-account"
      text
      color="error"
      class="pa-0 mx-0"
      v-if="existingAccount"
    >
      <v-list
        container
        dense
        light
        flat
        id="list-existing-account-details"
      >
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Username
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.username }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Name
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.lastName }}, {{existingAccount.firstName}}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Company
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.companyName }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Status
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.status }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Account Expiry
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{formatDisplayDate(existingAccount.accountExpiry)}}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Sponsor Employee Number
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text">
            {{ existingAccount.sponsorEmployeeNumber }}
          </v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <v-list-item-title class="secondary--text">
            Sponsor Organization Code
          </v-list-item-title>
          <v-list-item-subtitle class="font-weight-regular secondary--text text-wrap">
            {{ existingAccount.sponsorOrgCode }}
          </v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </v-alert>
    <v-flex v-else-if="type === 'Boolean'">
      <v-checkbox
        v-model="f.value"
        :label="label"
        :required="isRequired"
        :disabled="isDisplayMode"
        :rules="rules"
        dense
      ></v-checkbox>
    </v-flex>
    <v-flex v-else-if="type === 'Date'
      && !(status === 'Completed' && requestType === 'ACCOUNT_REACTIVATION')"
    >
      <date-input
        :date.sync="f.value"
        :allowedDates="allowedDates"
        :rules="dateRules"
        :isDisplayMode="isDisplayMode"
        :isRequired="isRequired"
        :label="label"
        :hint="hint"
      ></date-input>
    </v-flex>
    <v-flex v-else-if="type === 'Applicant'">
      <applicant-select
        :applicants="f.items"
        :selectedApplicant.sync="f.value"
        :disabled="true"
        :readOnly="true"
        :label="label"
        :hint="hint"
        :rules="rules"
        dense
      ></applicant-select>
    </v-flex>
    <v-flex v-else-if="type === 'Organization'">
      <sponsor-organization-select
        v-if="requestType === 'ACCOUNT_CREATION'"
        :applicant="user"
        :sponsorOrganizations="f.items"
        :selectedOrganization.sync="f.value"
        :label="label"
        :disabled="requestType !== 'ACCOUNT_CREATION' || (isResubmit || isDisplayMode) ? true : false"
        :readOnly="requestType !== 'ACCOUNT_CREATION' || (isResubmit || isDisplayMode) ? true : false"
        :hint="hint"
        :rules="rules.concat(sponsorOrgSelectRules)"
        @updateSelectedSponsorOrgManagerEmployeeNumber="updateSelectedSponsorOrgManagerEmployeeNumber"
      ></sponsor-organization-select>
    </v-flex>
    <v-flex v-else-if="type === 'Sponsor'">
      <sponsor-select
        v-if="requestType === 'ACCOUNT_CREATION' || selectedGuestAccount"
        :sponsors="f.items"
        :selectedSponsor.sync="f.value"
        :disabled="requestType === 'ACCOUNT_CREATION' && !isDisplayMode && !isResubmit ? false : true"
        :readOnly="requestType === 'ACCOUNT_CREATION' && !isDisplayMode && !isResubmit ? false : true"
        :label="label"
        :hint="hint"
        :rules="rules.concat(sponsorSelectRules)"
        @updateSelectedSponsorOrgCode="updateSelectedSponsorOrgCode"
        dense
      ></sponsor-select>
    </v-flex>
    <v-flex v-else-if="type === 'Account'">
      <guest-account-select
        :selectedGuestAccount.sync="f.value"
        :keyword.sync="input"
        :label="label"
        :disabled="isResubmit || isDisplayMode"
        :readOnly="isResubmit || isDisplayMode"
        :hint="hint"
        :requestType="requestType"
        :fieldsToDisplay="fieldsToDisplay"
        :rules="rules"
        :selectedGuestAccountSponsorEmployeeNumber.sync="selectedGuestAccountSponsorEmployeeNumber"
        :selectedGuestAccountSponsorOrgCode.sync="selectedGuestAccountSponsorOrgCode"
      ></guest-account-select>
    </v-flex>
    <v-flex v-else-if="type === 'Services'">
      <services-select 
        :request="request"
        :readOnly="isResubmit || isDisplayMode"
        :selectedGuestAccount="f.value"
        :existingServices="existingServices"
        :allServices="allServices"
        :error-messages="f.errors"
        :requestType="requestType"
        @servicesSelectedChanged="(emittedServiceChangedObject) => this.selectedServiceChanged(emittedServiceChangedObject)" 
      ></services-select>
    </v-flex>
  </v-flex>
</template>

<script>
import DateInput from '@/components/InputTypes/DateInput.vue'
import { useUserStore } from '@/store/user'
import { useAccountStore } from '@/store/account'
import { mapActions, mapState } from 'pinia'
import SponsorOrganizationSelect from './InputTypes/SponsorOrganizationSelect.vue'
import ApplicantSelect from './InputTypes/ApplicantSelect.vue'
import SponsorSelect from './InputTypes/SponsorSelect.vue'
import GuestAccountSelect from './InputTypes/GuestAccountSelect.vue'
import ServicesSelect from './InputTypes/ServicesSelect.vue'
import FormattingMixin from '@/mixins/formatting.mixin'
import AccountMixin from '@/mixins/account.mixin'

export default {
  props: {
    item: Object,
    request: Object,
    field: Object,
    type: String,
    label: String,
    hint: String,
    rules: Array,
    fieldsToDisplay: Array,
    isRequired: Boolean,
    isDisplayMode: Boolean,
    isResubmit: Boolean,
    isEditable: Boolean,
    maxlength: Number,
    requestType: String,
    status: String,
    existingServices: [],
    allServices: [],
    selectedSponsorOrgManagerEmployeeNumber: String,
    selectedSponsorEmployeeNumber: String,
    selectedSponsorOrgCode: String
  },
  name: 'RequestFormField',
  components: {
    DateInput,
    SponsorOrganizationSelect,
    SponsorSelect,
    ApplicantSelect,
    GuestAccountSelect,
    ServicesSelect
  },
  mixins: [ FormattingMixin, AccountMixin ],
  data() {
    return {
      f: {
        name: null,
        value: null,
        fields: [],
        items: [],
        errors: []
      },
      input: '',
      allowedDates: null,
      dateRules: [],
      existingAccount: null,
      selectedServices: [],
      selectedGuestAccount: null,
      selectedGuestAccountSponsorEmployeeNumber: null,
      selectedGuestAccountSponsorOrgCode: null
      // selectedSponsorOrgManagerEmployeeNumber: null,
      // selectedSponsorEmployeeNumber: null,
      // selectedSponsorOrgCode: null
    }
  },
  computed: {
    emailRules() {
      return [
        (v) => !!v || 'This is a required field.',
        (v) => (!!v && v.length <= 100) || 'Maximum 100 characters.',
        (v) =>
          !v ||
          !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
            v
          ) ||
          'Must be in Latin characters.',
        (v) => 
          !v ||
          // (/(?:[a-z0-9!#$%&*+/=?^_{|}-]+(?:\.[a-z0-9!#$%&*+/=?^_{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]?[a-z0-9])+|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
          //   v
          // ) &&
            (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
              v
            ) &&
          // v.match(/.+?(?=@)/)[0].length <= 60 &&
          // v.match(/(?<=\@).*/)[0].length <= 254) ||
            v.match(/(?<=\@).*/)[0].length <= 254 &&
            v.match(/(?<=\@).*/)[0].length >= 2 &&
            v.match(/.+?(?=@)/)[0].length <= 64) ||
          'Email must be valid.',
        (v) => !this.existingAccount || 'Account with the same email address already exists.'
      ]
    },
    sponsorSelectRules() {
      return [
        (v) => this.selectedSponsorEmployeeNumber !== this.selectedSponsorOrgManagerEmployeeNumber || 'Sponsor and sponsoring organization manager cannot be the same.'
      ]
    },
    sponsorOrgSelectRules() {
      return [
        (v) => this.canSubmitRequest(this.selectedSponsorOrgCode, this.user) || 'Applicant and sponsoring organization manager cannot be the same.',
        (v) => this.selectedSponsorOrgManagerEmployeeNumber !== this.selectedSponsorEmployeeNumber || 'Sponsor and sponsoring organization manager cannot be the same.'
      ]
    },
    ...mapState(useUserStore, ['user'])
  },
  watch: {
    'f.value': {
      deep: true,
      handler(value) {
        this.$emit('update', {
          name: this.field.name,
          value
        })

        if (this.field.name === 'guestAccountUsername') {
          this.$emit('update', {
            name: 'sponsorEmployeeNumber',
            value: this.selectedGuestAccountSponsorEmployeeNumber
          })

          this.$emit('update', {
            name: 'sponsorOrgCode',
            value: this.selectedGuestAccountSponsorOrgCode
          })
        }

        if (this.field.name === 'sponsorEmployeeNumber') {
          this.$emit('update:selectedSponsorEmployeeNumber', value)
        }

        if (this.field.name === 'sponsorOrgCode') {
          this.$emit('update:selectedSponsorOrgCode', value)
          this.$emit('update:selectedSponsorOrgManagerEmployeeNumber', this.selectedSponsorOrgManagerEmployeeNumber)
        }
      }
    },
    user: {
      handler(value) {
        if (value) {
          this.setField()
        }
      }
    },
    requestType: {
      handler(value) {
        if (value) {
          this.setField()
          this.$parent.resetValidation()
        }
      }
    },
    existingAccount: {
      handler(value) {
        if (value) {
          this.f.errors = ['Account with the same email address already exists.']
        } else {
          this.f.errors = []
        }
      }
    },
    selectedGuestAccountSponsorEmployeeNumber: {
      handler(value) {
        this.$emit('update', {
            name: 'sponsorEmployeeNumber',
            value: value
          })
      }
    },
    selectedGuestAccountSponsorOrgCode: {
      handler(value) {
        this.$emit('update', {
            name: 'sponsorOrgCode',
            value: value
          })
      }
    }
  },
  methods: {
    async searchForExistingAccount(v) {
      if (v) {
        this.$emit('isValidating', true)
        await this.searchAccountsByEmailAsync(v)
          .then((res) => {
            if (res.data) {
              this.existingAccount = res.data
            } else {
              this.existingAccount = null
            }
            this.$emit('isValidating', false)
          })
          .catch((error) => {
            this.existingAccount = null
            this.$emit('isValidating', false)
          })
      } else {
        this.existingAccount = null
      }
    },
    async setField() {
      switch (this.field.name) {
        case 'applicantEmployeeNumber': {
          if (this.item.fields[this.field.name] === null) {
            this.f.value = _.trim(this.user.employeeNumber)
            this.f.items = [this.user]
          } else {
            this.f.value = this.item.fields[this.field.name]
          }
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
          break
        }
        case 'sponsorEmployeeNumber': {
          if (this.requestType === 'ACCOUNT_CREATION') {
            if (this.item.fields[this.field.name] === null) {
              this.f.value = _.trim(this.user.employeeNumber)
              this.f.items = [this.user]
            } else {
              this.f.value = this.item.fields[this.field.name]
            }
            this.$emit('update', {
              name: this.field.name,
              value: this.f.value
            })
          }
          break
        }
        case 'sponsorOrgCode': {
          if (this.item.fields[this.field.name] === null) {
            this.f.value = _.trim(this.user.organizations[0].code)
            this.f.items = this.user.organizations
          } else {
            this.f.value = this.item.fields[this.field.name]
          }
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
          break
        }
        case 'accountExpiry': {
          if (this.item.fields[this.field.name]) {
            this.f.value = this.item.fields[this.field.name]
          } else {
            this.f.value = this.$moment().add(180, 'days').format('YYYY-MM-DD')
          }
          this.allowedDates = (val) =>
            val >= this.$moment().add(14, 'days').format('YYYY-MM-DD') &&
            val <= this.$moment().add(180, 'days').format('YYYY-MM-DD')
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
          this.dateRules = [
            (v) => !!v || 'This is a required field.',
            (v) => !v || (v >= this.$moment().add(14, 'days').format('YYYY-MM-DD') &&
            v <= this.$moment().add(180, 'days').format('YYYY-MM-DD')) || 'Account expiry must be between 2 weeks and 180 days from now.'
          ]
          break
        }
        case 'newAccountExpiry': {
          if (this.item.fields[this.field.name]) {
            this.f.value = this.item.fields[this.field.name]
          } else {
            this.f.value = this.$moment().add(180, 'days').format('YYYY-MM-DD')
          }
          this.allowedDates = (val) =>
            val >= this.$moment().add(14, 'days').format('YYYY-MM-DD') &&
            val <= this.$moment().add(180, 'days').format('YYYY-MM-DD')
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
          this.dateRules = [
            (v) => !!v || 'This is a required field.',
            (v) => !v || (v >= this.$moment().add(14, 'days').format('YYYY-MM-DD') &&
            v <= this.$moment().add(180, 'days').format('YYYY-MM-DD')) || 'New acount expiry must be between 2 weeks and 180 days from now.'
          ]
          break
        }
        case 'guestAccountUsername': {
          if (this.item.fields[this.field.name]) {
            this.f.value = this.item.fields[this.field.name]
            this.input = this.f.value.username
          } else {
            this.f.value = null
            this.input = ''
          }
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
          break
        }
        case 'emailAddress':
        {
          this.f.value = this.item.fields[this.field.name]
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
          await this.searchForExistingAccount(this.f.value)
          break
        }
        default: {
          this.f.value = this.item.fields[this.field.name]
          this.$emit('update', {
            name: this.field.name,
            value: this.f.value
          })
        }
      }
    },
    selectedServiceChanged(userSelectedServices) {
      this.selectedServices = userSelectedServices
      this.$emit('update', this.selectedServices) // Send the new list of services to the parent control
    },
    updateSelectedSponsorOrgManagerEmployeeNumber(val) {
      this.$emit('update:selectedSponsorOrgManagerEmployeeNumber', val)
    },
    updateSelectedSponsorOrgCode(val) {
      this.$emit('update:selectedSponsorOrgCode', val)
    },
    updateSelectedSponsorEmployeeNumber(val) {
      this.$emit('update:selectedSponsorEmployeeNumber', val)
    },
    ...mapActions(useAccountStore, ['searchAccountsByEmailAsync'])
  },

  created() {
    if (this.user) {
      this.setField()
    }
  }
}
</script>

<style>
.v-subheader {
  padding: 0 22px !important;
  height: 36px;
}
.v-text-field__details {
  padding: 0 22px !important;
}

#list-existing-account-details {
  background: transparent !important;
}

#alert-existing-account .v-alert__content {
  overflow: hidden;
}
</style>