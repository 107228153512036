<template>
  <v-flex>
    <v-subheader>{{ label }}<span class="secondary--text">*</span></v-subheader>
    <v-autocomplete
      rounded
      filled
      :items="accounts"
      v-model="select"
      :loading="loading || debouncing"
      :search-input.sync="search"
      no-filter
      item-value="username"
      auto-select-first
      :disabled="disabled"
      :readonly="readOnly"
      :rules="rules"
      :required="isRequired"
      @change="updateAccountFormFields"
      clearable
      dense
    >
      <template v-slot:append v-if="hint">
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" color="grey lighten-1">
              mdi-help-circle-outline
            </v-icon>
          </template>
          {{ hint }}
        </v-tooltip>
      </template>
      <template slot="selection" slot-scope="data">
        {{ data.item.username }}
      </template>

      <template v-slot:no-data>
        <v-list-item>
          <template v-if="!search">
            <v-list-item-title>Search for guest account</v-list-item-title>
          </template>
          <template v-else-if="!validSearch">
            <v-list-item-title
              >The keyword should be more than 3 characters.</v-list-item-title
            >
          </template>
          <template v-else>
            <v-list-item-title
              >No result for <strong>{{ search }}</strong></v-list-item-title
            >
          </template>
        </v-list-item>
      </template>

      <template v-slot:item="data">
        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.firstName }} {{ data.item.lastName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.username }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </v-autocomplete>

    <template v-if="selectObj">
      <v-flex class="pt-4">
        <v-row v-for="(field, i) in formFields" :key="i">
          <v-col class="py-0">
            <account-form-field
              :item="item"
              :field="field"
              :type="field.type"
              :label="field.label"
              :isRequired="field.required"
              :isEditable="field.editable"
            >
            </account-form-field>
          </v-col>
        </v-row>
      </v-flex>
    </template>
  </v-flex>
</template>

<script>
import { useAccountStore } from '@/store/account'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'
import AccountFormField from '@/components/AccountFormField.vue'

export default {
  components: { AccountFormField },
  props: {
    label: { type: String, default: 'Guest Account' },
    multiple: { type: Boolean, default: false },
    keyword: { type: String },
    selectedGuestAccount: { type: String },
    selectedGuestAccountSponsorEmployeeNumber: { type: String },
    selectedGuestAccountSponsorOrgCode: { type: String },
    disabled: { type: Boolean },
    readOnly: { type: Boolean },
    hint: { type: String },
    requestType: { type: String },
    fieldsToDisplay: { type: Array },
    rules: { type: Array },
    isRequired: { type: Boolean }
  },
  data() {
    return {
      loading: false,
      select: null,
      search: '',
      debouncing: false,
      debounceTimeout: null,
      item: null,
      options: {
        page: 1,
        itemsPerPage: 1,
        query: null
      }
    }
  },
  computed: {
    selectObj() {
      return this.accounts.find((x) => x.username === this.select)
    },
    formFields() {
      return this.fieldsToDisplay
    },
    validSearch() {
      return this.search && this.search.length >= 3
    },
    ...mapState(useAccountStore, ['account', 'accounts'])
  },
  methods: {
    async querySelections(val) {
      this.loading = true
      let requestType = this.requestType
      if (this.disabled) {
        requestType = ''
      }
      await this.searchAccountsAsync(val, requestType)
        .then((res) => {
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
    },
    updateSelectedGuestAccount() {
      if (!_.isEmpty(this.selectedGuestAccount)) {
        this.search = this.selectedGuestAccount
        this.select = this.selectedGuestAccount
        this.updateAccountFormFields()
      }
    },
    updateAccountFormFields() {
      let item = {
        fields: []
      }

      for (let f of this.fieldsToDisplay) {
        if (this.selectObj) {
          this.$set(
            item.fields,
            f.name,
            _.get(this.selectObj, f.name)
          )
        } else {
          this.$set(item.fields, f.name, null)
        }
      }
      this.item = item
      this.$emit('update:selectedGuestAccountSponsorEmployeeNumber', item.fields['sponsorEmployeeNumber'])
      this.$emit('update:selectedGuestAccountSponsorOrgCode', item.fields['sponsorOrgCode'])
    },
    ...mapActions(useAccountStore, ['searchAccountsAsync', 'resetAccounts'])
  },
  watch: {
    search(val) {
      if (this.validSearch) {
        this.debouncing = true
        this.querySelections(val)
        clearTimeout(this.debounceTimeout)
        this.debounceTimeout = setTimeout(() => (this.debouncing = false), 300)
      }
    },
    select(o) {
      if (o !== null && o !== undefined) {
        this.$emit('update:keyword', o)
        this.$emit('update:selectedGuestAccount', o)
      } else {
        this.$emit('update:keyword', null)
        this.$emit('update:selectedGuestAccount', null)
      }
    },
    selectedGuestAccount() {
      this.updateSelectedGuestAccount()
    },
    selectObj(val) {
      this.updateAccountFormFields()
    },
    requestType(val) {
      this.search = null
      this.select = null
      this.resetAccounts()
    }
  },
  created() {
    this.updateSelectedGuestAccount()
    this.resetAccounts()
  }
}
</script>