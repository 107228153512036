import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[(this.showCheckboxes)?_c('div',[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.label)+" "),_c('span',{staticClass:"secondary--text"},[_vm._v("*")])]),_c('div',{attrs:{"id":"checkboxGroupErrorMessage"}},[_vm._v(_vm._s(_vm.checkboxGroupErrorMessage))]),_c(VListItemGroup,{attrs:{"multiple":""}},_vm._l((_vm.allServices),function(thisService){return _c('span',{key:thisService.id},[_c(VListItem,{class:thisService.alreadyHasService === true ? 'already-has-service' : 'editable-service'},[_c(VListItemAction,[_c(VCheckbox,{attrs:{"color":thisService.isReadOnly === true ? 'black' : 'green',"label":thisService.sspDisplayName ? thisService.sspDisplayName : thisService.name,"value":thisService.id,"disabled":thisService.isReadOnly,"ripple":!thisService.isReadOnly,"model-value":thisService.id,"selectedGuestAccount":_vm.selectedGuestAccount,"rules":_vm.selectedCheckboxesRules,"hide-details":true},on:{"change":function($event){return _vm.onChange()}},model:{value:(_vm.servicesSelected),callback:function ($$v) {_vm.servicesSelected=$$v},expression:"servicesSelected"}})],1),_c(VListItemContent,[_c(VListItemSubtitle,{staticClass:"text--primary"},[_vm._v(_vm._s(thisService.sspDescription ? thisService.sspDescription : thisService.description))])],1)],1)],1)}),0)],1):_c('div',[_c(VSubheader,[_vm._v(" "+_vm._s(_vm.selectedServicelabel)+" ")]),_c(VTextField,{attrs:{"rounded":"","filled":"","color":"black","disabled":true,"readonly":true,"dense":""},model:{value:(_vm.requestedServiceString),callback:function ($$v) {_vm.requestedServiceString=$$v},expression:"requestedServiceString"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }