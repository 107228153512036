<template>
  <v-form ref="form" lazy-validation>
    <v-flex v-if="item.requestType">
      <v-row>
        <v-col class="py-0">
          <v-subheader>
            Request Type
            <span class="secondary--text">*</span>
          </v-subheader>
          <v-select
            v-model="item.requestType"
            rounded
            filled
            :items="requestTypes"
            item-text="label"
            item-value="name"
            :disabled="isResubmit || isDisplayMode"
            required
            @change="updateRequestType(item.requestType)"
            dense
          ></v-select>
        </v-col>
      </v-row>

      <v-row v-for="(field, i) in formFields" :key="i">
        <v-col class="py-0">
          <request-form-field
            :item="item"
            :request="request"
            :field="field"
            :rules="field.rules"
            :type="field.type"
            :hint="field.hint"
            :label="field.label"
            :isDisplayMode="isDisplayMode"
            :isRequired="field.required"
            :isEditable="field.editable"
            :maxlength="field.maxlength"
            :isResubmit="isResubmit"
            :requestType="item.requestType && (item.requestType.name || item.requestType)"
            :existingServices="item.servicesSelected"
            :allServices="item.allServices"
            @update="updateField"
            :fieldsToDisplay="field.fieldsToDisplay"
            :status="status"
            @isValidating="setIsValidating"
            :selectedSponsorOrgManagerEmployeeNumber.sync="selectedSponsorOrgManagerEmployeeNumber"
            :selectedSponsorEmployeeNumber.sync="selectedSponsorEmployeeNumber"
            :selectedSponsorOrgCode.sync="selectedSponsorOrgCode"
          >
          </request-form-field>
        </v-col>
      </v-row>

      <v-row v-if="!isDisplayMode" class="text-right">
        <v-col>
          <v-btn class="mx-2" depressed @click="back">
            Back to List
          </v-btn>
          <v-btn
            v-if="isResubmit"
            class="mx-2"
            color="secondary"
            :loading="isSubmitting"
            depressed
            @click="validate"
            :disabled="isValidating"
          >
            Resubmit
          </v-btn>
          <v-btn
            v-else
            class="mx-2"
            color="secondary"
            :loading="isSubmitting"
            depressed
            @click="validate"
            :disabled="isValidating"
          >
            Submit
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-else class="text-right">
        <v-col>
          <v-btn class="mx-2" depressed @click="back">
            Back to List
          </v-btn>
        </v-col>
      </v-row>
      <v-snackbar v-model="requestSnackbar.value" :color="requestSnackbar.color" vertical>
        <span v-html="requestSnackbar.text"></span>
        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="requestSnackbar.value = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-flex>
  </v-form>
</template>

<script>
import requestTypes from '@/store/requestTypes.js'
import RequestFormField from '@/components/RequestFormField.vue'
import { useRequestStore } from '@/store/request'
import { useAccountStore } from '@/store/account'
import { useServiceStore } from '@/store/service'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'

export default {
  props: {
    isDisplayMode: Boolean,
    isResubmit: Boolean,
    request: Object,
    type: String,
    guestAccountUsername: String
  },
  name: 'RequestForm',
  components: { RequestFormField },
  data() {
    return {
      isSubmitting: false,
      isValidating: false,
      timeout: 3000,
      item: {
        requestType: null,
        fields: [],
        servicesSelected: [],
        allServices: [] 
      },
      guestAccountStatus: null,
      selectedGuestAccount: null,
      selectedSponsorOrgManagerEmployeeNumber: null,
      selectedSponsorEmployeeNumber: null,
      selectedSponsorOrgCode: null
    }
  },
  computed: {
    requestTypes() {
      return requestTypes
    },
    formFields: {
      get() {
        let fields = []
        if (this.selectedType) {
          if (this.guestAccountStatus === 'SUSPENDED' && this.selectedType === 'ACCOUNT_REACTIVATION') {
            fields = this.requestTypes
                .find((x) => x.name === this.selectedType)
                .fields.filter(
                  (x) => !(!x.readOnly && (x.name === 'type' || x.name === 'newAccountExpiry')) && !x.readOnly
                )
          } else {
            fields = this.requestTypes
                .find((x) => x.name === this.selectedType)
                .fields.filter(
                  (x) => !(!x.readOnly && x.name === 'type') && !x.readOnly
                )
          }
        }
        return fields
      },
      set(v) {
        return v
      }
    },
    ...mapState(useRequestStore, ['requestSnackbar', 'selectedType', 'status'])
  },
  watch: {
    'item.fields': {
      deep: true,
      async handler(v) {
        if (v['guestAccountUsername']) {
          await this.getGuestAccountStatus(v['guestAccountUsername'])
        }
      }
    },
    selectedGuestAccount: {
      handler(v) {
        console.log(v)
      }
    }
  },
  methods: {
    back() {
      if (
        this.$route.name === 'show-request' ||
        this.$route.name === 'create-request'
      ) {
        this.$router.push({ name: 'list-requests' })
      }
      if (this.$route.name === 'show-approval') {
        this.$router.push({ name: 'list-approvals' })
      }
    },
    setIsValidating(v) {
      this.isValidating = v
    },
    updateRequestType(val) {
      this.updateSelectedType(val)
      this.updateItem()
    },
    async getGuestAccountStatus(username) {
      let blnIncludeServicesWithFetch = false
      if (this.selectedType === 'SERVICE_SUBSCRIPTION' ) {
        blnIncludeServicesWithFetch = true
      }
      await this.fetchAccountAsync(username, blnIncludeServicesWithFetch).then((res) => {
        if (res.success) {
          this.guestAccountStatus = res.data.status
          this.$set(this.item, 'servicesSelected', res.data.servicesSelected) //similar to 'this.item.servicesSelected = res.data.servicesSelected', but the latter is very intermittent unless we set it on the viewmodel.
        }
      })
    },
    async resubmit() {
      this.isSubmitting = true
      await this.updateRequestAsync(this.request.requestId, this.item).then(
        (res) => {
          this.isSubmitting = false
          if (res) {
            this.$router.push({ name: 'list-requests' })
          }
        }
      )
    },
    async submit() {
      this.isSubmitting = true
      await this.createRequestAsync(this.item).then((res) => {
        this.isSubmitting = false
        if (res) {
          this.$router.push({ name: 'list-requests' })
        }
      })
    },
    updateItem() {
      let item = {
        requestType: this.selectedType,
        fields: this.item.fields,
        allServices: this.item.allServices
      }

      for (let f of this.formFields) {
        if (this.request) {
          if (
            f.name === 'requestType' ||
            f.name === 'applicantEmployeeNumber' ||
            f.name === 'sponsorEmployeeNumber' ||
            f.name === 'sponsorOrgCode'
          ) {
            this.$set(item.fields, f.name, _.get(this.request.request, f.name))
          } else if (f.name === 'guestAccountUsername' && this.guestAccountUsername) {
            this.$set(item.fields, f.name, this.guestAccountUsername)
          } else {
            this.$set(item.fields, f.name, _.get(this.request, f.name))
          }
        } else {
          if (f.name === 'guestAccountUsername' && this.guestAccountUsername) {
            this.$set(item.fields, f.name, this.guestAccountUsername)
          } else {
            this.$set(item.fields, f.name, null)
          }
        }
      }
      this.item = item
    },
    async updateField(fieldObject) {
      if (!_.isEqual(this.item.fields[fieldObject.name], fieldObject.value)) {
        this.$set(this.item.fields, fieldObject.name, fieldObject.value)
        if (fieldObject.name === 'guestAccountUsername' && fieldObject.value) {
          await this.getGuestAccountStatus(fieldObject.value)
        }
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        if (this.isResubmit) {
          await this.resubmit()
        } else {
          await this.submit()
        }
      }
    },
    ...mapActions(useRequestStore, [
      'createRequestAsync',
      'updateRequestAsync',
      'updateSelectedType'
    ]),
    ...mapActions(useAccountStore, [
      'fetchAccountAsync'
    ]),
    ...mapActions(useServiceStore, [
      'fetchServicesAsync'
    ])
  },
  async created() {

    if (this.request) {
      this.updateRequestType(this.request.request.requestType)
    } else {
      if (this.type !== undefined) {
        this.updateRequestType(this.type)
      } else {
        this.updateRequestType('ACCOUNT_CREATION')
      }
    }

    //Load and cache all services in the background once, even if we don't use them for this specific request. 
     this.fetchServicesAsync().then((res) => {
      if (res.success) {
        this.$set(this.item, 'allServices', res.data) 
        //Note that this fetchServicesAsync() call is not awaited, so the changes happened in the background. Now it's complete, so update the form.
      }
    })

  }
}
</script>

<style scoped>
.mdi-send::before {
  transform: rotate(-25deg);
  margin-top: -5px;
}
</style>