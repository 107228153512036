import ApiService from '@/service/api.service.js'
import { defineStore } from 'pinia'

export const useApplicantStore = defineStore('applicant', {
  state: () => ({
    applicant: null,
    applicants: null
  }),
  getters: {
    getApplicant(state) {
      return state.applicant
    },
    getApplicants(state) {
      return state.applicants
    }
  },
  actions: {
    async searchApplicantsAsync(q) {
      // let params = `perPage=${options.itemsPerPage}&page=${options.page}&query=${options.query}`

      ApiService.customRequest({
        method: 'get',
        url: `/Search/Employees/${q}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.applicants = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async fetchApplicantAsync(employeeNumber) {
      ApiService.customRequest({
        method: 'get',
        url: `/Employee/${employeeNumber}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.applicant = result.data
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }
})
