import { defineStore } from 'pinia'
import ApiService from '@/service/api.service.js'
import { useUserStore } from '@/store/user'

export const useRequestStore = defineStore('request', {
  state: () => ({
    request: null,
    status: null,
    selectedType: null,
    requests: [],
    totalRequests: 0,
    requestHeaders: [
      {
        text: 'Request Type',
        value: 'requestType'
      },
      {
        text: 'Document Number',
        value: 'rawsDocumentNumber'
      },
      {
        text: 'Created At',
        value: 'createdAt'
      },
      {
        text: 'Updated At',
        value: 'updatedAt'
      },
      {
        text: 'Requester',
        value: 'applicant.longAccount'
      },
      {
        text: 'Sponsor',
        value: 'sponsor.longAccount'
      },
      {
        text: 'Status',
        value: 'rawsStatus'
      }
    ],
    requestHeadersWidget: [
      {
        text: 'Request Type',
        value: 'requestType'
      },
      {
        text: 'Document Number',
        value: 'rawsDocumentNumber'
      },
      {
        text: 'Status',
        value: 'rawsStatus'
      }
    ],
    requestSnackbar: {
      text: null,
      color: null,
      value: false
    }
  }),
  getters: {
    getRequest(state) {
      return state.request
    },
    getRequests(state) {
      return state.requests
    }
  },
  actions: {
    updateSelectedType(v) {
      this.selectedType = v
    },
    async updateRequestAsync(id, data) {
      let typeSpecificFields = data.fields
      let request = {}
      let obj = {}

      for (let key of Object.keys(typeSpecificFields)) {
        if (key === 'applicantEmployeeNumber') {
          request[key] = _.trim(typeSpecificFields[key])
        } else if (key === 'sponsorEmployeeNumber') {
          request[key] = _.trim(typeSpecificFields[key])
        } else if (key === 'sponsorOrgCode') {
          request[key] = _.trim(typeSpecificFields[key])
        }
      }

      request.requestType = data.requestType

      for (let key of Object.keys(typeSpecificFields)) {
        if (key !== 'applicantEmployeeNumber' && key !== 'sponsorOrgCode') {
          obj[key] = _.trim(typeSpecificFields[key])
        }
      }

      obj['request'] = request

      let requestType = null
      switch (request.requestType) {
        case 'SERVICE_SUBSCRIPTION':
          requestType = 'ServiceSubscriptionRequests'
          break
        case 'ACCOUNT_REACTIVATION':
          requestType = 'AccountReactivationRequests'
          break
        case 'ACCOUNT_EXPIRY_EXTENSION':
          requestType = 'AccountExpiryExtensionRequests'
          break
        case 'ACCOUNT_CREATION':
        default:
          requestType = 'AccountCreationRequests'
          break
      }

      return ApiService.customRequest({
        method: 'put',
        url: `${requestType}/${id}`,
        headers: { 'Content-Type': 'application/json' },
        data: obj
      })
        .then((result) => {
          this.requestSnackbar = {
            text: 'Request resubmitted',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else if (error.response.status == 422) {
            let errors = []
            _.forEach(error.response.data.errors, (val, key) => {
              console.log(key)
              _.forEach(val, (v) => {
                errors.push(v)
              })
            })
            this.requestSnackbar = {
              text: `<b>${error.response.data.title}</b><br/>${errors.join('<br/>')}`,
              color: 'red darken-2',
              value: true
            }
          } else if (error.response.status == 400) {
            this.requestSnackbar = {
              text: `${error.response.data.message}`,
              color: 'red darken-2',
              value: true
            }
          } else {
            this.requestSnackbar = {
              text: `${error.message}.`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async createRequestAsync(data) {
      let typeSpecificFields = data.fields
      let request = {}
      let obj = {}

      
      for (let key of Object.keys(typeSpecificFields)) {
        if (key === 'applicantEmployeeNumber') {
          request[key] = _.trim(typeSpecificFields[key])
        } else if (key === 'sponsorEmployeeNumber') {
          request[key] = _.trim(typeSpecificFields[key])
        } else if (key === 'sponsorOrgCode') {
          request[key] = _.trim(typeSpecificFields[key])
        }
        else {
          obj[key] = _.trim(typeSpecificFields[key])
        }
      }

      request.requestType = data.requestType

      obj['request'] = request

      let requestType = null
      switch (request.requestType) {
        case 'SERVICE_SUBSCRIPTION':
          requestType = 'ServiceSubscriptionRequests'
          break
        case 'ACCOUNT_REACTIVATION':
          requestType = 'AccountReactivationRequests'
          break
        case 'ACCOUNT_EXPIRY_EXTENSION':
          requestType = 'AccountExpiryExtensionRequests'
          break
        case 'ACCOUNT_CREATION':
        default:
          requestType = 'AccountCreationRequests'
          break
      }

      return ApiService.customRequest({
        method: 'post',
        url: `${requestType}`,
        headers: { 'Content-Type': 'application/json' },
        data: obj
      })
        .then((result) => {
          this.requestSnackbar = {
            text: 'Request submitted',
            color: 'green darken-2',
            value: true
          }
          return true
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else if (error.response.status == 422) {
            let errors = []
            _.forEach(error.response.data.errors, (val, key) => {
              console.log(key)
              _.forEach(val, (v) => {
                errors.push(v)
              })
            })
            this.requestSnackbar = {
              text: `<b>${error.response.data.title}</b><br/>${errors.join('<br/>')}`,
              color: 'red darken-2',
              value: true
            }
          } else if (error.response.status == 400) {
            this.requestSnackbar = {
              text: `${error.response.data.message}`,
              color: 'red darken-2',
              value: true
            }
          } else {
            this.requestSnackbar = {
              text: `${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
          return false
        })
    },
    async fetchRequestAsync(rawsDocumentNumber) {
      let requestType = null

      let prefix = rawsDocumentNumber.match(/.*(?=-)/)[0]

      switch (prefix) {
        case 'SSP004':
          requestType = 'ServiceSubscriptionRequests'
          break
        case 'SSP003':
          requestType = 'AccountReactivationRequests'
          break
        case 'SSP002':
          requestType = 'AccountExpiryExtensionRequests'
          break
        case 'SSP001':
        default:
          requestType = 'AccountCreationRequests'
          break
      }

      return ApiService.customRequest({
        method: 'get',
        url: `${requestType}/${rawsDocumentNumber}`,
        headers: { 'Content-Type': 'application/json' }
      })
        .then((result) => {
          this.request = result.data
          this.status = result.data.request.rawsStatus
          return {
            success: true,
            data: result.data
          }
        })
        .catch((error) => {
          return {
            success: false,
            error
          }
        })
    },
    async fetchRequestsAsync(options) {
      let orderBy = []
      options.sortBy.map((obj, i) => {
        if (options.sortDesc[i]) {
          orderBy.push(`${obj} desc`)
        } else {
          orderBy.push(`${obj} asc`)
        }
      })
      const userStore = useUserStore()

      let params = `perPage=${options.itemsPerPage}&page=${options.page}&applicantEmployeeNumber=${userStore.user.employeeNumber}`
      if (orderBy.length > 0) {
        params += `&orderBy=${orderBy}`
      }

      ApiService.customRequest({
        method: 'get',
        url: `/Requests?${params}`,
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          let pagination = JSON.parse(res.headers['x-pagination'])
          this.totalRequests = pagination.TotalItemCount
          this.requests = res.data
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.requestSnackbar = {
              text: 'You are not logged in, please reload to refresh your login token.',
              color: 'red darken-2',
              value: true
            }
          } else {
            this.requestSnackbar = {
              text: `Failed to update request list. Error: ${error.message}`,
              color: 'red darken-2',
              value: true
            }
          }
        })
    }
  }
})
