import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c(VSubheader,[_vm._v(_vm._s(_vm.label)),_c('span',{staticClass:"secondary--text"},[_vm._v("*")])]),_c(VAutocomplete,{attrs:{"rounded":"","filled":"","items":_vm.accounts,"loading":_vm.loading || _vm.debouncing,"search-input":_vm.search,"no-filter":"","item-value":"username","auto-select-first":"","disabled":_vm.disabled,"readonly":_vm.readOnly,"rules":_vm.rules,"required":_vm.isRequired,"clearable":"","dense":""},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event},"change":_vm.updateAccountFormFields},scopedSlots:_vm._u([(_vm.hint)?{key:"append",fn:function(){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c(VIcon,_vm._g({attrs:{"color":"grey lighten-1"}},on),[_vm._v(" mdi-help-circle-outline ")])]}}],null,false,4161362875)},[_vm._v(" "+_vm._s(_vm.hint)+" ")])]},proxy:true}:null,{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.username)+" ")]}},{key:"no-data",fn:function(){return [_c(VListItem,[(!_vm.search)?[_c(VListItemTitle,[_vm._v("Search for guest account")])]:(!_vm.validSearch)?[_c(VListItemTitle,[_vm._v("The keyword should be more than 3 characters.")])]:[_c(VListItemTitle,[_vm._v("No result for "),_c('strong',[_vm._v(_vm._s(_vm.search))])])]],2)]},proxy:true},{key:"item",fn:function(data){return [_c(VListItemContent,[_c(VListItemTitle,[_vm._v(" "+_vm._s(data.item.firstName)+" "+_vm._s(data.item.lastName)+" ")]),_c(VListItemSubtitle,[_vm._v(" "+_vm._s(data.item.username)+" ")])],1)]}}],null,true),model:{value:(_vm.select),callback:function ($$v) {_vm.select=$$v},expression:"select"}}),(_vm.selectObj)?[_c(VFlex,{staticClass:"pt-4"},_vm._l((_vm.formFields),function(field,i){return _c(VRow,{key:i},[_c(VCol,{staticClass:"py-0"},[_c('account-form-field',{attrs:{"item":_vm.item,"field":field,"type":field.type,"label":field.label,"isRequired":field.required,"isEditable":field.editable}})],1)],1)}),1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }