const commonFields = [
  {
    name: 'requestId',
    label: 'ID',
    type: 'Int',
    readOnly: true,
    rules: []
  },
  {
    name: 'rawsId',
    label: 'Raws ID',
    type: 'String',
    readOnly: true,
    rules: []
  },
  {
    name: 'rawsDocumentNumber',
    label: 'Document Number',
    type: 'String',
    readOnly: true,
    rules: []
  },
  {
    name: 'rawsPersonInCharge',
    label: 'Approver Employee Number',
    type: 'String',
    readOnly: true,
    required: false,
    rules: []
  },
  {
    name: 'requestType',
    label: 'Request Type',
    type: 'String',
    readOnly: true,
    editable: true,
    commonField: true,
    rules: []
  },
  {
    name: 'applicantEmployeeNumber',
    label: 'Applicant',
    type: 'Applicant',
    hint: 'Rakuten employee requesting.',
    readOnly: false,
    editable: false,
    commonField: true,
    required: true,
    rules: [(v) => !!v || 'This is a required field']
  },
  {
    name: 'sponsorOrgCode',
    label: 'Sponsor Organization',
    hint: `Rakuten organization sponsoring the guest. The applicant's and sponsor's main organization by default.`,
    type: 'Organization',
    readOnly: false,
    required: true,
    editable: true,
    commonField: true,
    rules: [(v) => !!v || 'This is a required field']
  },
  {
    name: 'sponsorEmployeeNumber',
    label: 'Sponsor',
    hint: 'Rakuten employee sponsoring the guest.',
    type: 'Sponsor',
    readOnly: false,
    editable: true,
    commonField: true,
    required: true,
    rules: [(v) => !!v || 'This is a required field']
  },
  {
    name: 'modificationDateTime',
    label: 'Last Modified At',
    readOnly: true,
    type: 'DateTime',
    rules: []
  },
  {
    name: 'rawsStatus',
    label: 'Status',
    readOnly: true,
    type: 'String',
    rules: []
  }
]

const commonHeaders = [
  {
    text: 'Document Number',
    value: 'request.rawsDocumentNumber'
  },
  {
    text: 'Target User',
    value: 'emailAddress'
  },
  {
    text: 'Target User Company',
    value: 'companyShortName'
  },
  {
    text: 'Created At',
    value: 'request.updatedAt'
  },
  {
    text: 'Updated At',
    value: 'request.updatedAt'
  },
  {
    text: 'Status',
    value: 'request.rawsStatus'
  }
]

export default [
  {
    name: 'ACCOUNT_CREATION',
    label: 'Account Creation',
    apiEndpoint: '/AccountCreationRequests',
    rawsFormId: 'SSP0001',
    fields: commonFields.concat([
      {
        name: 'familyName',
        label: 'Guest Last Name in Latin characters',
        type: 'String',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'givenName',
        label: 'Guest First Name in Latin characters',
        type: 'String',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'familyNameLocal',
        label: 'Guest Last Name in local language',
        type: 'String',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'givenNameLocal',
        label: 'Guest First Name in local language',
        type: 'String',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 50) || 'Maximum 50 characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 50
      },
      {
        name: 'emailAddress',
        label: 'Guest Company Email Address',
        type: 'Email',
        hint: `Guest's work email address only (i.e. john.doe@microsoft.com). Personal email address is prohibited.`,
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 100) || 'Maximum 100 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => 
            !v ||
            // (/(?:[a-z0-9!#$%&*+/=?^_{|}-]+(?:\.[a-z0-9!#$%&*+/=?^_{|}-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]?[a-z0-9])+|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(
            //   v
            // ) &&
              (/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                v
              ) &&
            // v.match(/.+?(?=@)/)[0].length <= 60 &&
            // v.match(/(?<=\@).*/)[0].length <= 254) ||
              v.match(/(?<=\@).*/)[0].length <= 254 &&
              v.match(/(?<=\@).*/)[0].length >= 2 &&
              v.match(/.+?(?=@)/)[0].length <= 64) ||
            'Email must be valid.'
        ],
        maxlength: 100
      },
      {
        name: 'companyName',
        label: 'Partner Company Name in Latin characters',
        hint: 'Official name of the company where guest belongs to in Latin characters.',
        type: 'String',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 200) || 'Maximum 200 characters.',
          (v) =>
            !v ||
            !/[^\u0000-\u024F\u1E00-\u1EFF\u2C60-\u2C7F\uA720-\uA7FF]/.test(
              v
            ) ||
            'Must be in Latin characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 200
      },
      {
        name: 'companyNameLocal',
        label: 'Partner Company Name in local language',
        type: 'String',
        hint: 'Official name of the company where guest belongs to in local language.',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 200) || 'Maximum 200 characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 200
      },
      {
        name: 'companyShortName',
        label: 'Partner Company Common Name/Abbreviation',
        type: 'String',
        readOnly: false,
        required: true,
        editable: true,
        rules: [
          (v) => !!v || 'This is a required field.',
          (v) => (!!v && v.length <= 40) || 'Maximum 40 characters.',
          (v) => !v || !/^\s*$/.test(v) || 'This is a required field.'
        ],
        maxlength: 40
      },
      {
        name: 'accountExpiry',
        label: 'Account Expiry',
        hint: 'Date account will expire',
        type: 'Date',
        readOnly: false,
        required: true,
        editable: true,
        rules: [(v) => !!v || 'This is a required field.']
      },
      {
        name: 'servicesSelected',
        label: 'Services',
        type: 'Services',
        readOnly: false,
        required: true,
        editable: true,
        rules: [] //This rule is not defined here.
      },
      {
        name: 'agreementConfirmed',
        label:
          `I have confirmed vendor security assessment completed or NDA in place with the contractor's company.`,
        type: 'Boolean',
        readOnly: false,
        required: true,
        editable: true,
        rules: [(v) => !!v || 'You must agree to continue.']
      }
    ]),
    headers: commonHeaders
  },
  {
    name: 'ACCOUNT_EXPIRY_EXTENSION',
    label: 'Account Expiry Extension',
    apiEndpoint: '/AccountExpiryExtensionRequests',
    rawsFormId: 'SSP0002',
    fields: commonFields.concat([
      {
        name: 'guestAccountUsername',
        label: 'Guest Account',
        type: 'Account',
        readOnly: false,
        required: true,
        editable: true,
        rules: [(v) => !!v || 'This is a required field.'],
        fieldsToDisplay: [
          {
            name: 'sponsorOrgCode',
            label: 'Sponsor Organization',
            hint: `Rakuten organization sponsoring the guest. The applicant's and sponsor's main organization by default.`,
            type: 'Organization',
            readOnly: true,
            required: true,
            editable: false,
            rules: [(v) => !!v || 'This is a required field']
          },
          {
            name: 'sponsorEmployeeNumber',
            label: 'Sponsor',
            hint: 'Rakuten employee sponsoring the guest.',
            type: 'Sponsor',
            readOnly: true,
            editable: false,
            required: true,
            rules: [(v) => !!v || 'This is a required field']
          },
          {
            name: 'lastName',
            label: 'Last Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'firstName',
            label: 'First Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'email',
            label: 'Email Address',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'companyName',
            label: 'Company Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'accountExpiry',
            label: 'Account Expiry',
            hint: 'Date account will expire',
            type: 'Date',
            required: true,
            readOnly: true,
            editable: false
          }
        ]
      },
      {
        name: 'newAccountExpiry',
        label: 'New Account Expiry',
        hint: 'Date account will expire',
        type: 'Date',
        readOnly: false,
        required: true,
        editable: true,
        rules: [(v) => !!v || 'This is a required field.']
      }
    ]),
    headers: commonHeaders
  },
  {
    name: 'ACCOUNT_REACTIVATION',
    label: 'Account Reactivation',
    apiEndpoint: '/AccountReactivationRequests',
    rawsFormId: 'SSP0003',
    fields: commonFields.concat([
      {
        name: 'guestAccountUsername',
        label: 'Guest Account',
        type: 'Account',
        readOnly: false,
        required: true,
        editable: true,
        rules: [(v) => !!v || 'This is a required field.'],
        fieldsToDisplay: [
          {
            name: 'sponsorOrgCode',
            label: 'Sponsor Organization',
            hint: `Rakuten organization sponsoring the guest. The applicant's and sponsor's main organization by default.`,
            type: 'Organization',
            readOnly: true,
            required: true,
            editable: false,
            rules: [(v) => !!v || 'This is a required field']
          },
          {
            name: 'sponsorEmployeeNumber',
            label: 'Sponsor',
            hint: 'Rakuten employee sponsoring the guest.',
            type: 'Sponsor',
            readOnly: true,
            editable: false,
            required: true,
            rules: [(v) => !!v || 'This is a required field']
          },
          {
            name: 'lastName',
            label: 'Last Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'firstName',
            label: 'First Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'email',
            label: 'Email Address',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'companyName',
            label: 'Company Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'accountExpiry',
            label: 'Account Expiry',
            hint: 'Date account will expire',
            type: 'Date',
            required: true,
            readOnly: true,
            editable: false
          }
        ]
      },
      {
        name: 'newAccountExpiry',
        label: 'New Account Expiry',
        hint: 'Date account will expire',
        type: 'Date',
        readOnly: false,
        editable: true,
        rules: []
      }
    ]),
    headers: commonHeaders
  },
  {
    name: 'SERVICE_SUBSCRIPTION',
    label: 'Service Subscription',
    apiEndpoint: '/ServiceSubscriptionRequests',
    rawsFormId: 'SSP0004',
    fields: commonFields.concat([
      {
        name: 'guestAccountUsername',
        label: 'Guest Account',
        type: 'Account',
        readOnly: false,
        required: true,
        editable: true,
        rules: [(v) => !!v || 'This is a required field.'],
        fieldsToDisplay: [
          {
            name: 'sponsorOrgCode',
            label: 'Sponsor Organization',
            hint: `Rakuten organization sponsoring the guest. The applicant's and sponsor's main organization by default.`,
            type: 'Organization',
            readOnly: true,
            required: true,
            editable: false,
            rules: [(v) => !!v || 'This is a required field']
          },
          {
            name: 'sponsorEmployeeNumber',
            label: 'Sponsor',
            hint: 'Rakuten employee sponsoring the guest.',
            type: 'Sponsor',
            readOnly: true,
            editable: false,
            required: true,
            rules: [(v) => !!v || 'This is a required field']
          },
          {
            name: 'lastName',
            label: 'Last Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'firstName',
            label: 'First Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'email',
            label: 'Email Address',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'companyName',
            label: 'Company Name',
            type: 'String',
            required: true,
            readOnly: true,
            editable: false
          },
          {
            name: 'accountExpiry',
            label: 'Account Expiry',
            hint: 'Date account will expire',
            type: 'Date',
            required: true,
            readOnly: true,
            editable: false
          }
        ]
      },
      {
        name: 'servicesSelected',
        label: 'Services',
        type: 'Services',
        readOnly: false,
        required: true,
        editable: true,
        rules: [] //This rule is not defined here.
      }
    ]),
    headers: commonHeaders
  }
]
