<template>
  <v-flex>
    <div v-if="this.showCheckboxes">
      <v-subheader>
      {{ label }}
      <span class="secondary--text">*</span>
      </v-subheader>
      <div id="checkboxGroupErrorMessage">{{ checkboxGroupErrorMessage }}</div>

      <v-list-item-group multiple >
        <span v-for="thisService in allServices" :key="thisService.id">
          <v-list-item :class="thisService.alreadyHasService === true ? 'already-has-service' : 'editable-service'">
            <v-list-item-action>
              <v-checkbox
                v-model="servicesSelected" 
                @change="onChange()"
                :color="thisService.isReadOnly === true ? 'black' : 'green'"
                :label="thisService.sspDisplayName ? thisService.sspDisplayName : thisService.name"
                :value="thisService.id"
                :disabled="thisService.isReadOnly"
                :ripple="!thisService.isReadOnly"
                :model-value="thisService.id"
                :selectedGuestAccount="selectedGuestAccount"
                :rules="selectedCheckboxesRules"
                :hide-details="true"
              ></v-checkbox>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-subtitle
                class="text--primary"
              >{{ thisService.sspDescription ? thisService.sspDescription : thisService.description }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </span>
      </v-list-item-group>
    </div>

    <div v-else>
      <v-subheader> {{ selectedServicelabel }} </v-subheader>
      <v-text-field
        v-model="requestedServiceString"
        rounded
        filled
        color="black"
        :disabled=true
        :readonly=true
        dense
      >
      
      </v-text-field>

    </div>


  </v-flex>
</template>

<script>
import { useAccountStore } from '@/store/account'
import { mapActions } from 'pinia'
import { mapState } from 'pinia'

export default {
  props: {
    label: { type: String, default: 'Please select at least one service to subscribe to' },
    selectedServicelabel: { type: String, default: 'Selected Service' },
    request: Object,
    readOnly: Boolean,
    selectedGuestAccount: { type: String },
    existingServices:  { type: [] },
    allServices:  { type: [] },
    requestType: String
  },
  data() {
    return {
      servicesSelected: [],
      formDirty: false,
      showCheckboxes: false,
      requestedServiceString: this.getRequestedServiceDisplayName()
    }
  },
  computed: {
    selectedCheckboxesRules() {
      let numNewServicesChecked = this.getNumNewServicesChecked()
      return [ this.formDirty === true && numNewServicesChecked > 0 || 'At least one new service must be selected' ]
    },
    checkboxGroupErrorMessage() {
      let ruleResult = this.selectedCheckboxesRules[0] //true if no problems

      let strResult = this.formDirty === true && ruleResult !== true ? ruleResult : ''
      return strResult
    },
    ...mapState(useAccountStore, ['account', 'accountServices'])
  },
  watch: {
    servicesSelected() {
      let servicesKeyValue =  {
        name : 'servicesSelected', 
        value : this.servicesSelected
      }
      this.$emit('servicesSelectedChanged', servicesKeyValue)
    },
    existingServices() {
      //The existing services changed, probably from the parent control selecting a different user, or different request type.
      //So automatically check the existingServices on servicesSelected, and clear all other servicesSelected.
      if (this.existingServices) {
        this.servicesSelected = this.existingServices //ensure that servicesSelected is not null.
      }
      else{
        this.servicesSelected = []
      }

      //go through all services, and if the user already has access to this one, then mark it as read-only.
      this.allServices.forEach(thisService => {
        if (this.existingServices == null) {
          //This may be an Account creation, and a user that doesn't exist cannot have any services
          thisService.isReadOnly = false
          thisService.alreadyHasService = false
        }
        else{
          thisService.isReadOnly = this.existingServices.includes(thisService.id)
          thisService.alreadyHasService = this.existingServices.includes(thisService.id)
        }
      })
      
      this.formDirty = true
    },
    allServices() {
      //All services loads in the background and returns after the form is loaded.
      if (!this.allServices) {
        return
      }

      if (this.requestType === 'ACCOUNT_CREATION' ) { 
        if (this.request && this.request.selectedServices) {
          this.servicesSelected = this.request.selectedServices ///update the checkbox selection/model with what was selected from the original request.
        }
      }

      this.allServices.forEach(thisService => {
        if (this.requestType === 'SERVICE_SUBSCRIPTION') {
          thisService.isReadOnly = true //On initial creation, there is no user. So make all checkboxes disabled.
          thisService.alreadyHasService = false
        }
        if (this.requestType === 'ACCOUNT_CREATION' ) {
          thisService.isReadOnly = this.request !== undefined && this.request.request.rawsStatus !== 'Applicant' //for new requests, editable. For old requests, readonly.
          thisService.alreadyHasService = this.request && this.request.selectedServices && this.request.selectedServices.includes(thisService.id)
        }
      })
    }
  },
  methods: {
    onChange() {
      //console.log(this.servicesSelected) // for testing
      let servicesKeyValue =  {
        name : 'servicesSelected', 
        value : this.servicesSelected
      }
      this.$emit('servicesSelectedChanged', servicesKeyValue) //On any change of this Select control, send the updated array to the parent control, 
      this.formDirty = true
    },
    async updateSelectedGuestAccountAsync() { //TODO: am I supposed to do something?
      // await this.searchAccountsAsync(val, requestType)
      //   .then((res) => {
      //     this.loading = false
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    /// Returns the number of services that are checked, excluding services that the user already has. Only counts brand new services to be added.
    getNumNewServicesChecked() {
      if (this.servicesSelected == null) {
        //For new account creations, servicesSelected and existingServices are probably null, so return zero.
        return 0
      }
      return this.servicesSelected.length - (this.existingServices == undefined ? 0 : this.existingServices.length)
    },
    getRequestedServiceDisplayName() {
      //Used when a single service was selected. Show the single service display name + description. 
      let finalName = ''
      let finalDescription = ''
      if (this.request === undefined || this.request.requestedService === undefined)
      {
        return ''
      }

      let requestedService = this.request.requestedService

      if (this.request.requestedService !== undefined) {
        finalName = requestedService.sspDisplayName ?? requestedService.name ?? requestedService.Id ?? 'n/a'
        finalDescription = requestedService.sspDescription ?? requestedService.description ?? requestedService.Id ?? ''
        if (finalDescription !== '') {
          finalDescription = ' (' + finalDescription + ')'
        }
      }

      let finalString = finalName + finalDescription
      return finalString
      
    },
    ...mapActions(useAccountStore, ['searchAccountsAsync', 'resetAccounts']) //TODO: is this used?
  },
  created() {
    this.updateSelectedGuestAccountAsync()
    
    if (this.requestType === 'SERVICE_SUBSCRIPTION' && this.readOnly) {
      this.showCheckboxes = !this.readOnly
    }
    else { //probably ACCOUNT_CREATION
      this.showCheckboxes = true
    }

    if (this.request && this.request.request && this.request.request.rawsStatus === 'Applicant') {
      this.formDirty = true
    } else {
      this.formDirty = false
    }
  }
}
</script>

<style>
.already-has-service {
  background-color: #ECFFF2; /* light green div, the service is already subscribed to. */
}

.v-list-item__action .v-input .v-messages {
  display: hidden; /* hide the checkbox error messages, since this should behave like a checkbox group. */
}

#checkboxGroupErrorMessage {
  color: red;
  padding: 0 22px !important;
  max-height: 36px;
}

</style>